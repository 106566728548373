/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { AnimationDownloads } from '../components/Lotties';
import DownloadButton from '../components/DownloadButton';
import { FaAndroid } from 'react-icons/fa'
import { FaFirefoxBrowser } from "react-icons/fa";
import { FaMobile } from "react-icons/fa";

function Downloads() {
    return (
        <section className='relative' id="downloads">

            <div className='absolute w-full'>
                <div className='flex flex-col mx-auto w-fit rounded-full py-4 bg-white shadow-lg shadow-green-300'>
                    <AnimationDownloads width={300} title="Downloads" />
                </div>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#f3f4f5" fillOpacity="1" d="M0,96L34.3,101.3C68.6,107,137,117,206,106.7C274.3,96,343,64,411,74.7C480,85,549,139,617,133.3C685.7,128,754,64,823,58.7C891.4,53,960,107,1029,138.7C1097.1,171,1166,181,1234,176C1302.9,171,1371,149,1406,138.7L1440,128L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
            </svg>
            
            <div style={{ background: "#f3f4f5" }} className='w-full'>
                <div className='mx-auto max-w-6xl grid mobile:grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3'>

                    <DownloadButton href="https://play.google.com/store/apps/details?id=com.m2kdevelopments.lindle" platform="Android" textColor='text-green-700' shadowHighlight="shadow-green-200">
                        <FaAndroid color="green" size={60} />
                    </DownloadButton>

                    <DownloadButton comingsoon={true} platform="iOS" textColor='text-gray-400' shadowHighlight="shadow-gray-200">
                        <img src="/ios.svg" width={60} alt="ios" />
                    </DownloadButton>

                    <DownloadButton comingsoon={true} platform="PWA" textColor='text-purple-400' shadowHighlight="shadow-purple-200">
                        <FaMobile color="purple" size={60} />
                    </DownloadButton>

                    <DownloadButton href="https://chromewebstore.google.com/detail/lindle/igkkojjaikfmiibedalhgmfnjohlhmaj" platform="Chrome" textColor='text-amber-400' shadowHighlight="shadow-yellow-200">
                        <img src="/chrome.svg" width={60} alt="chrome" />
                    </DownloadButton>

                    <DownloadButton comingsoon={true} platform="Fire Fox" textColor='text-orange-600' shadowHighlight="shadow-orange-500">
                        <FaFirefoxBrowser color="orange" size={60} />
                    </DownloadButton>

                    <DownloadButton comingsoon={true} platform="Zoom" textColor='text-blue-400' shadowHighlight="shadow-blue-200">
                        <img src="/zoom.svg" width={60} alt="zoom" />
                    </DownloadButton>

                    <DownloadButton comingsoon={true} platform="VS Code" textColor='text-blue-400' shadowHighlight="shadow-blue-200">
                        <img src="/vscode.svg" width={60} alt="vscode" />
                    </DownloadButton>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#f3f4f5" fillOpacity="6" d="M0,288L80,240C160,192,320,96,480,74.7C640,53,800,107,960,133.3C1120,160,1280,160,1360,160L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
            </svg>
        </section>
    )
}

export default Downloads