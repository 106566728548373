import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

function Footer() {

  return (
    <section className='w-full'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1f2937" fillOpacity="1" d="M0,128L48,133.3C96,139,192,149,288,133.3C384,117,480,75,576,64C672,53,768,75,864,90.7C960,107,1056,117,1152,106.7C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      <footer className="bg-gray-800 py-8">
        <div className='p-4 grid grid-cols-1 phone:grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-4'>

          <div className='mr-24'>
            <Image src="/logoText.png" width={200} height={200} alt="Logo" />
            <p className='text-slate-300'>We value your privacy. We do not collect or store your browsing history or personal data.</p>
          </div>

          <div aria-label="Quick Links" className='w-full'>
            <h3 className='mobile:text-xl tablet-xl:text-3xl font-bold text-green-300 pt-4'>Resources</h3>
            <br />
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="https://www.youtube.com/watch?v=TmoPWAKL2WY&list=PLSWFyXTD_LK7DqHWhq_qdcVNnN4KwYCUU&index=2">Tutorials</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="/api-docs">API Reference</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="https://github.com/Lindle-me/">Github</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="/developers-libraries">Developer Libraries</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="/obs">Connect with OBS</Link>
            {/* <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="/affiliates">Affiliate Program</Link> */}
          </div>

          <div aria-label="Quick Links" className='w-full'>
            <h3 className='mobile:text-xl tablet-xl:text-3xl font-bold text-green-300 pt-4'>Support And Community</h3>
            <br />
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="https://facebook.com/lindle">Join Facebook Group</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="https://chat.whatsapp.com/KWA4KrPQwfQKz56zb1MUtG">Join WhatsApp Group</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="https://www.paypal.com/paypalme/m2kdevelopment">Support Developer</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="https://www.buymeacoffee.com/m2kdevelopments">Buy Me A Coffee</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="https://support.m2kdevelopments.com">Help Desk</Link>
          </div>

          <div aria-label="Quick Links" className='w-full'>
            <h3 className='mobile:text-xl tablet-xl:text-3xl font-bold text-green-300 pt-4'>Legal</h3>
            <br />
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="/disclaimer">Disclaimer</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="/cookiepolicy">Cookie Policy</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="/privacypolicy">Privacy Policy</Link>
            <Link className='text-white mobile:text-md tablet:text-lg w-full block transition-all duration-300 hover:text-green-300' href="/terms">Terms</Link>
          </div>

        </div>
        <div className='w-full text-right px-10 text-green-50'>
          <p>© 2023 <Link aria-label="Main Website" className="text-slate-200" href="https://m2kdevelopments.com">M2k Developments</Link>. All rights reserved</p>
        </div>
      </footer>
    </section>


  )
}

export default Footer
