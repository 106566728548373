/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import Link from 'next/link';
import { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Fade, Slide } from "react-awesome-reveal";
import YouTube from 'react-youtube';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import FAQ from '../utils/faq.json';
import { AnimationIntegration, AnimationQrcode } from '../components/Lotties';
import FEATURES from '../utils/features.json';
import PriceCard from '../components/PriceCard';

import { AnimationPhone } from '../components/Lotties';
import swal from 'sweetalert';
import QRCode from 'qrcode';
import Downloads from '../views/Downloads'
import { FaDownload } from 'react-icons/fa';



export const getStaticProps = async (pr) => {
  const dev = process.env.NODE_ENV !== 'production';
  const response = await fetch(dev ? `http://localhost:3000/api/prices` : `https://app.lindle.me/api/prices`);
  const priceplans = await response.json();
  return { props: { priceplans } };
};


export default function Home({ priceplans }) {

  const [url, setUrl] = useState("");
  const [light, setLight] = useState("#FFFFFF");
  const [dark, setDark] = useState("#000000");

  const onQrcode = async () => {
    if (url.replace(/\s/gmi, '') == '') return;
    const qrcode = await QRCode.toDataURL(url, {
      width: 500, color: {
        dark: dark, light: light
      }
    });
    const result = await swal({
      title: `Your Code`,
      icon: qrcode,
      buttons: ['CANCEL', 'DOWNLOAD']
    });
    if (!result) return;

    // Download image
    const a = document.createElement('a');
    a.download = `lindle-qrcode.png`;
    a.href = qrcode;
    a.click();
    a.remove();
  }


  return (
    <div >
      <Head>
        <title>Lindle</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="All your links, All at once, All in one place!" />
        <link rel="icon" href="/favicon.ico" />

        {/* Facebook */}
        <meta property="og:title" content="Lindle" />
        <meta property="og:description" content="All your links, All at once, All in one place!" />
        <meta property="og:image" content="/logoText.png" />
        <meta property="og:url" content="https://lindle.me" />
        <meta property="og:type" content="website" />

        <meta name="twitter:title" content="Lindle" />
        <meta name="twitter:description" content="All your links, All at once, All in one place!" />
        <meta name="twitter:image" content="/logoText.png" />
        <meta name="twitter:card" content="/bannerText.png" />

        {/* pinterest */}
        <meta name="pinterest-rich-pin" content="true" />
      </Head>

      {/* Navigation */}
      <Navigation />

      <main className='w-full justify-end self-center mx-auto px-6'>


        {/* <Background Image /> */}
        <img src="mobile.png" alt="Lindle" className="absolute mobile:opacity-10 tablet:opacity-20 mobile:left-0 laptop:right-0 rotate-45 mobile:w-48 tablet:w-96 -top-4" />


        {/* Title Section */}
        <Slide>
          <section className='phone:w-full tablet:w-[90%] laptop:w-[80%] mx-auto mobile:my-14 tablet:my-48 grid mobile:grid-cols-1 laptop:grid-cols-2 mobile:px-0 tablet:px-14'>

            <div className='justify-center mobile:text-center tablet:text-left'>
              <TypeAnimation
                sequence={[
                  'All your links',
                  1000,
                  'All at once',
                  1000,
                  'All in one place!',
                  1000,
                ]}
                speed={50}
                style={{ fontSize: '3rem', textAlign: "center" }}
                repeat={Infinity}
              />
              <p className='text-xl'>Lindle is your ultimate solution for managing and organizing your web links with ease. This versatile Chrome extension empowers you to save, categorize, and access your online resources like never before. Say goodbye to the chaos of scattered bookmarks and hello to streamlined productivity.</p>
              <div className='w-full mx-auto my-10'>
                <Link className='text-center max-w-sm flex p-3 mobile:text-lg tablet:text-2xl bg-green-700 text-white hover:bg-amber-500 rounded-lg shadow-lg shadow-green-500 hover:shadow-amber-400 cursor-pointer duration-500' href="/#downloads">
                  <FaDownload />
                  <span className='px-3'>Get Lindle Today</span>
                </Link>
              </div>
            </div>


            <div className='flex justify-end items-end flex-col'>
              <AnimationPhone width="80%" />
              <div className='grid mobile:grid-cols-1 tablet-xl:grid-cols-2'>
                <Link href="https://play.google.com/store/apps/details?id=com.m2kdevelopments.lindle"><img width={240} alt="Google Play" src="googleplay.png" /></Link>
                <Link href="https://chrome.google.com/webstore/detail/igkkojjaikfmiibedalhgmfnjohlhmaj"><img width={240} alt="Chrome Store" src="chromestore.png" /></Link>
              </div>
            </div>
          </section>
        </Slide>

        <section className='mx-auto w-full text-center my-[70px] bg-gradient-to-br from-green-50 to-green-200 rounded-3xl'>
          <div className='mobile:px-0 tablet:px-32'>
            <p className='pt-20 px-10 font-bold mobile:text-xl tablet:text-5xl text-green-900'>Lindle is your ultimate solution for managing and organizing your web links with ease</p>
            <p className='px-10 italic mobile:text-sm tablet:text-3xl text-green-700'>Lindle is your ultimate solution for managing and organizing your web links with ease</p>
          </div>
          <div className='grid tablet:grid-cols-1 laptop:grid-cols-3 mobile:p-0 tablet:p-28'>

            <Fade>
              <div className='mobile:px-1 tablet:px-8 text-white bg-slate-500 rounded-2xl shadow-2xl shadow-green-900 hover:bg-green-500 py-20 duration-500 mobile:mx-2 tablet:mx-12 my-10'>
                <p className='mobile:text-xl tablet:text-3xl'>Effortless Link Saving</p>
                <p className='py-10'>Save links from the web with a single click, and watch as they&apos;re neatly organized in one central hub.</p>
                <Link href="#features">Read More</Link>
              </div>
            </Fade>


            <Fade>
              <div className='mobile:px-1 tablet:px-8 text-white bg-gray-700 rounded-2xl shadow-2xl shadow-purple-900 hover:bg-purple-500 py-20 duration-500 mobile:mx-2 tablet:mx-12 my-10'>
                <p className='mobile:text-xl tablet:text-3xl'>Intelligent Auto-Sorting</p>
                <p className='py-10'>Lindle automatically categorizes your saved links based on their source, whether it&apos;s from social media platforms like Facebook, Instagram, LinkedIn, or other websites.</p>
                <Link href="#features">Read More</Link>
              </div>
            </Fade>


            <Fade>
              <div className='mobile:px-1 tablet:px-8 text-white bg-slate-500 rounded-2xl shadow-2xl shadow-amber-900 hover:bg-amber-500 py-20 duration-500 mobile:mx-2 tablet:mx-12 my-10'>
                <p className='mobile:text-xl tablet:text-3xl'>Cross-Device Sync</p>
                <p className='py-10'>Create custom folders to sort and manage your links. Keep everything neatly tucked away for quick access</p>
                <Link href="#features">Read More</Link>
              </div>
            </Fade>

          </div>


        </section>

        {/* Downloads */}
        <Downloads />


        {/* Integrations */}
        <div className='absolute'>
          <img src="mobile.png" alt="Lindle" className="relative opacity-10 -left-3 -rotate-45 mobile:w-[200px] tablet:w-[400px] -top-4" />
        </div>
        <Slide>
          <section className='mx-auto mobile:w-full tablet:w-full tablet-xl:w-2/3 text-center mobile:my-18 tablet:my-36 grid mobile:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2'>
            <div>
              <h2 className='mobile:text-3xl laptop:text-7xl my-4 text-left text-green-950 font-bold'>Integrations</h2>
              <span className='mobile:text-lg laptop:text-3xl my-4 italic text-left mb-2'>Coming Soon</span>
              {/* Styles in the css file */}
              <div className='text-left mobile:text-xl laptop:text-4xl'>
                <li>Bitly</li>
                <li>Google Meets</li>
                <li>Zoom</li>
                <li>Zapier</li>
                <li>Pabbly</li>
              </div>
            </div>
            <AnimationIntegration />
          </section>
        </Slide>


        <section className='mx-auto mobile:w-full tablet:w-full tablet-xl:w-2/3 text-center mobile:my-18 tablet:my-36'>
          <div className='my-16 drop-shadow-2xl p-2 h-fit w-full'>
            <YouTube videoId="gCJd1Lu-a8k"
              title="Lindle"
              opts={{
                height: '600',
                width: '100%',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 1,
                  origin: process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : 'https://app.lindle.me'
                },
              }}
              onReady={(event) => event.target.pauseVideo()} />
          </div>
        </section>


        {/* Features */}

        <section className='my-8 bg-gray-50 w-full py-16'>
          <div className='phone:w-full tablet:w-9/12 mx-auto '>
            <h2 id="features" className='mobile:text-3xl tablet:text-6xl text-green-900 mx-auto w-full text-center'>What You&apos;ll be Getting</h2>
            <div className='grid mobile:grid-cols-1 phone:grid-cols-1 tablet:grid-cols-1 tablet-xl:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3 mobile:p-2 tablet:p-10 max-w-2/3'>
              <div className='bg-white p-8 m-2 rounded-lg shadow-lg border-l-8 border-l-green-200 hover:shadow-2xl duration-700'>
                <h3 className='font-bold'>Seamless Integration with URL Shorteners</h3>
                <p>Enjoy the convenience of integrated Bitly and other popular URL shorteners to keep your links clean and concise.</p>
              </div>
              <div className='bg-white p-8 m-2 rounded-lg shadow-lg border-l-8 border-l-green-300 hover:shadow-2xl duration-700'>
                <h3 className='font-bold'>Intuitive Search Functionality</h3>
                <p>Easily find any link you&apos;ve saved by using our powerful search feature. No more endless scrolling or digging through folders.</p>
              </div>
              <div className='bg-white p-8 m-2 rounded-lg shadow-lg border-l-8 border-l-green-400 hover:shadow-2xl duration-700'>
                <h3 className='font-bold'>Folder Organization</h3>
                <p>Create custom folders to sort and manage your links. Keep everything neatly tucked away for quick access.</p>
              </div>
              <div className='bg-white p-8 m-2 rounded-lg shadow-lg border-l-8 border-l-green-500 hover:shadow-2xl duration-700'>
                <h3 className='font-bold'>Access from Messenger Platforms</h3>
                <p>Our extension embeds directly into Facebook Messenger, Instagram, and LinkedIn. You can access your saved links right from these platforms whenever you need them.</p>
              </div>
              <div className='bg-white p-8 m-2 rounded-lg shadow-lg border-l-8 border-l-green-600 hover:shadow-2xl duration-700'>
                <h3 className='font-bold'>Share with Ease</h3>
                <p>Share links with colleagues, friends, and family without leaving your preferred messenger platform.</p>
              </div>
              <div className='bg-white p-8 m-2 rounded-lg shadow-lg border-l-8 border-l-green-700 hover:shadow-2xl duration-700'>
                <h3 className='font-bold'>Clean, User-Friendly Interface</h3>
                <p>The extension is designed for simplicity and ease of use, making link management a breeze.</p>
              </div>
            </div>
          </div>

          {/* QR Code Generator */}
          <h2 className='mobile:text-2xl tablet:text-5xl text-green-900 mx-auto w-full text-center'>Try our QR Code Generator</h2>
          <div className='grid phone:grid-cols-1 tablet:grid-cols-2 phone:w-full tablet:w-1/2 mx-auto text-center'>
            <div>
              <AnimationQrcode width={250} />
              <p className='text-2xl'></p>
            </div>
            <div className='flex flex-col align-middle tablet:my-7 w-fit'>
              <input className="px-4 py-2 rounded-md text-2xl" type="url" name="link" placeholder="Enter You Link Here.." value={url} onChange={e => setUrl(e.target.value)} />
              <div className='flex my-3'>
                <input className="px-2 h-10 rounded-md text-2xl w-full" type="color" name="color" value={light} onChange={e => setLight(e.target.value)} />
                <input className="px-2 h-10 rounded-md text-2xl w-full" type="color" name="color" value={dark} onChange={e => setDark(e.target.value)} />
              </div>
              <button className='px-4 py-2 rounded-md text-2xl shadow-2xl shadow-green-400 bg-green-300 hover:bg-green-600 duration-500 hover:shadow-green-700 hover:text-green-50 flex align-middle items-center text-center' onClick={onQrcode}>Click for QR Code</button>
            </div>
          </div>
        </section>


        {/* Extra Download Section */}
        <section className='mx-auto my-40 text-center justify-center'>
          <Slide>
            <div className='mx-auto mobile:w-full phone:w-full tablet:w-9/12 w-[50%]'>

              <Link className='rounded-2xl text-center p-6 mobile:text-lg phone:text-2xl tablet:text-3xl tablet-xl:text-4xl laptop:text-5xl shadow-2xl shadow-green-400 bg-green-300 hover:bg-green-600 duration-500 hover:shadow-green-700 hover:text-green-50' href="https://chrome.google.com/webstore/detail/igkkojjaikfmiibedalhgmfnjohlhmaj" target='_blank'>
                Start Using Lindle TODAY!
              </Link>
            </div>
          </Slide>
        </section>



        {/* Pricing */}
        <div id="priceplans" className='w-full -left-5 absolute overflow-hidden h-[900px]'>
          <div className='w-[150%] mobile:h-[400px] tablet:h-[700px] bg-green-800 rotate-3 absolute top-32 left-[-40px] -z-10 overflow-x-hidden'></div>
        </div>
        <section className="mx-auto tablet-xl:w-full desktop:w-2/3 text-center mobile:my-18 tablet:my-36">
          <h2 className='mobile:text-2xl tablet:text-6xl my-24'>Pricing</h2>
          <Fade damping={1.0}>
            <div className='mx-auto grid mobile:grid-cols-1 tablet:grid-cols-1 tablet-xl:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3'>
              <PriceCard
                title="Free Forever"
                description=""
                url="https://chrome.google.com/webstore/detail/igkkojjaikfmiibedalhgmfnjohlhmaj"
                price={0}
                discount={0}
                features={FEATURES}
              />
              {
                priceplans.map(price =>
                  <PriceCard
                    key={price._id}
                    title={price.name}
                    description={price.description}
                    url={`/checkout/buy?id=${price._id}`}
                    price={price.price}
                    discount={price.discount}
                    features={price.features}
                  />
                )
              }
            </div>
          </Fade>

        </section>

        {/* FAQ */}
        <section className='phone:w-full tablet:w-9/12 mx-auto my-14'>

          <h2 className='font-bold mobile:text-3xl tablet:text-6xl text-lime-800 my-12'>Frequently Asked Questions</h2>

          <Accordion>
            {
              FAQ.map((faq, index) =>
                <Fade damping={1.0} key={index}>
                  <AccordionItem >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {faq.question}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        {faq.answer}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Fade>
              )
            }
          </Accordion>
        </section>


      </main>

      <Footer />
    </div >
  )
}
