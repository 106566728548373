import { useRouter } from 'next/router'
import React from 'react'
import swal from 'sweetalert';
import * as API from '../utils/api';

function DownloadButton({ href, platform, comingsoon, textColor, shadowHighlight, children }) {

    const rooter = useRouter()

    const onWaiting = async () => {
        const email = await swal({
            title: "Join Waiting List",
            text: `Be one of the first people to know when our ${platform} updates are out. Enter you email below.`,
            icon: "info",
            content: "input",
            buttons: ['Cancel', 'Add Me']
        });
        if (!email) return;
        if (!email.test(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) swal('Invalid Email');

        const res = await API.PostAPI('/api/email/waitinglist', email)
        swal('Waiting List', res.message, res.result ? 'success' : 'warning');
    }

    return (
        <div onClick={() => comingsoon ? onWaiting() : rooter.push(href)} className="flex justify-center">
            <div className='flex relative w-fit m-3'>
                <div style={{ zIndex: 3 }} className={`z-4 relative cursor-pointer rounded-full p-4 bg-white shadow-md shadow-grey-200 hover:shadow-xl hover:${shadowHighlight} duration-200`}>
                    {children}
                </div>
                <div className='z-1 relative flex flex-col justify-center w-48  -left-6 cursor-pointer'>
                    <div className={`bg-white rounded-tr-md pl-5 shadow-sm shadow-grey-100 hover:shadow-lg hover:${shadowHighlight} duration-200`}>
                        <span>{comingsoon ? "Coming Soon" : "Download For"}</span>
                    </div>
                    <div className={`rounded-md bg-white rounded-tr-md pl-5 shadow-sm shadow-grey-100 hover:shadow-lg hover:${shadowHighlight}  hover:font-bold duration-200`}>
                        <span className={textColor + ' text-2xl'}>{platform}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadButton